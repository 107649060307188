<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <b-card>
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
        >
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="1"
        >
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
        >
          <b-overlay
            :show="cargaCursos"
            spinner-variant="primary"
          >
            <b-form-select
              v-model="selectCurso"
              :options="optionsCursos"
              @change="changeCurso"
            />
          </b-overlay>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="items.length"
          />
        </b-col>
        <b-col
          lg="1"
          md="1"
          sm="1"
          class="my-1"
        >
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <b-col
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="Alumno"
            modulo="alumnos"
            @processAdd="addAlumno"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Header: Check -->
            <template #head(colCheck)="data">

              <b-form-checkbox
                :id="data.label"
                v-model="chkTodo"
              />

            </template>

            <!-- Column: Check -->
            <template #cell(colCheck)="data">

              <b-form-checkbox
                :id="`chk-${data.item.id}`"
                v-model="data.item.chkSelected"
              />

            </template>

            <!-- Column: Alumno -->
            <template #cell(nombres)="data">
              <colNombreImg
                :mostrarImg="false"
                :imagen="null"
                :nombre="`${data.item.nombres}
                  ${data.item.primerApellido} ${data.item.segundoApellido}`"
                :txtMuted="`${data.item.tipoDocumento}: ${formatRut(data.item.rut)}`"
                :nombreModal="null"
              />
            </template>

            <!-- COLUMNA Curso -->
            <template #cell(nombreGrado)="data">
              {{ data.item.nombreGrado }} {{ data.item.letra}}
            </template>

            <!-- COLUMNA Diagnóstico -->
            <template #cell(nombreDiagnostico)="data">
              <colDiagnostico
                :datos="data.item"
              />
            </template>

            <!-- COLUMNA ESTADO -->
            <template #cell(estado)="data">
              <colEstado
                :data="data"
                modulo="alumnos"
                @processUpdateEstado="updateEstado"
              />
            </template>

            <!-- Column: Action -->
            <template #cell(acciones)="data">
              <colAccionesBtnes
                modulo="alumnos"
                :modal="`modal-lg-${data.item.id}`"
                :data="data"
                @processGoToUpdate="goToUpdate"
                @processGoToClone="goToClone"
                @processRemove="remove(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>

      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard,
  BFormSelect,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'

import { rut } from '@core/mixins/ui/rut'

import btnCrear from '../../components/List/btnCrear.vue'
import inputFiltro from '../../components/List/inputFiltro.vue'
import btnMostrar from '../../components/List/btnMostrar.vue'
import colAccionesBtnes from '../../components/List/colAccionesBtnes.vue'
import colEstado from '../../components/List/colEstado.vue'
import colDiagnostico from '../../components/List/colDiagnostico.vue'
import spinner from '../../components/spinner.vue'
import colNombreImg from '../../components/List/colNombreImg.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCard,
    BFormSelect,

    colAccionesBtnes,
    btnCrear,
    inputFiltro,
    btnMostrar,
    colEstado,
    colDiagnostico,
    spinner,
    colNombreImg,
  },
  mixins: [rut],
  data() {
    return {
      cargando: true,
      spinner: false,
      cargaCursos: true,
      selectCurso: null,
      optionsCursos: [],

      // chk
      items: [],
      itemsGet: [],
      selectedchk: [],
      chkTodo: null,
      checked: null,

      perPage: 50,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [50, 100, 200],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'colCheck',
        //   label: 'chkHeader',
        //   sortable: false,
        //   thStyle: {
        //     width: '0px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'numLista',
          label: 'Lista',
          sortable: true,
          thStyle: {
            width: '5px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombres',
          label: 'Nombre',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreGrado',
          label: 'Curso',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreEstablecimiento',
          label: 'Establecimiento',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreDiagnostico',
          label: 'Diagnóstico Pie',
          sortable: false,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '100px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getAlumnos: 'alumnos/getAlumnos',
      getCursos: 'cursos/getCursos',
    }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
    disabledExport() {
      return this.chkCount()
    },
  },
  watch: {
    getAlumnos(val) {
      this.totalRows = val.length
      this.items = this.getAlumnos
      this.itemsGet = this.getAlumnos
    },
    chkTodo() {
      this.chkAll()
    },
  },
  mounted() {
    this.cargarAlumnos()
    this.setTableList()
    this.cargarCursos()
  },
  methods: {
    ...mapActions({
      fetchAlumnos: 'alumnos/fetchAlumnos',
      updateAlumnoPeriodo: 'alumnos/updateAlumnoPeriodo',
      removeAlumnos: 'alumnos/removeAlumnos',
      fetchCursos: 'cursos/fetchCursos',
    }),
    ...mapMutations('alumnos', ['setAlumno']),
    cargarCursos() {
      this.fetchCursos().then(() => {
        this.optionsCursos.push({
          value: null,
          text: 'Buscar por curso...',
        })
        this.getCursos.forEach(curso => {
          const text = `${curso.nombreGrado} ${curso.letra}`
          this.optionsCursos.push({
            value: curso.id,
            text,
          })
        })
        this.cargaCursos = false
      })
    },
    changeCurso() {
      this.cargando = true
      this.selectAsignatura = null
      this.disabledAsignatura = true
      this.filtroGrado()
    },
    filtroGrado() {
      this.items = []
      this.itemsGet.forEach(item => {
        this.items.push(item)
      })
      if (this.selectCurso) {
        const filtro = this.items.filter(i => i.idCurso === this.selectCurso)
        this.items = filtro
      }
      this.totalRows = this.items.length
      this.cargando = false
    },
    setTableList() {
      if (this.$can('update', 'alumnos')
        || this.$can('delete', 'alumnos')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    cargarAlumnos() {
      this.fetchAlumnos().then(() => {
        this.cargando = false
      })
    },
    addAlumno() {
      this.$router.replace({
        name: 'alumnos-create',
      })
    },
    updatePeriodo(alumno) {
      this.$swal({
        title: 'Actualizar periodo!',
        html: 'Estás seguro que deseas actualizar el periodo activo del'
          + ' alumno<br><span class="font-weight-bolder">'
          + `${alumno.nombre}</span>?`,
        footer: '<div class="text-center text-primary">Al actualizar el'
          + ' periodo activo, se creará un nuevo marco de trabajo para el'
          + ' alumno. No se puede devolver al periodo anterior.</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, actualízalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.updateAlumnoPeriodo(alumno).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Periodo activo actualizado!',
              html:
                'El periodo activo del alumno<br>'
                + ' <span class="font-weight-bolder">'
                + `${alumno.nombres} ${alumno.primerApellido} ${alumno.segundoApellido}</span>`
                + '<br>ha sido actualizado con éxito!',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
            this.spinner = false
            this.cargarAlumnos()
          })
        } else {
          this.spinner = false
          this.cargarAlumnos()
        }
      })
    },
    updateEstado() {
      // console.log('update')
    },
    goToUpdate(alumno) {
      this.setAlumno(alumno)
      this.$router.push({
        name: 'alumnos-update',
      })
    },
    goToClone(alumno) {
      this.setAlumno(alumno)
      this.$router.push({
        name: 'alumnos-clone',
      })
    },
    remove(alumno) {
      let title = ''
      let text = ''
      let confirmButtonText = ''
      if (alumno.genero === 'Femenino') {
        title = 'Eliminar alumna!'
        text = `Estás seguro que deseas eliminar la alumna
          "${alumno.nombres} ${alumno.primerApellido} ${alumno.segundoApellido}"?`
        confirmButtonText = 'Si, eliminala!'
      } else {
        title = 'Eliminar alumno!'
        text = `Estás seguro que deseas eliminar el alumno
          "${alumno.nombres} ${alumno.primerApellido} ${alumno.segundoApellido}"?`
        confirmButtonText = 'Si, eliminalo!'
      }
      this.$swal({
        title,
        text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText,
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.removeAlumnos(alumno.id).then(() => {
            if (alumno.genero === 'Femenino') {
              title = 'Eliminada con éxito!'
              text = `"${alumno.nombres} ${alumno.primerApellido} ${alumno.segundoApellido}" ha sido eliminada!`
            } else {
              title = 'Eliminado con éxito!'
              text = `"${alumno.nombres} ${alumno.primerApellido} ${alumno.segundoApellido}" ha sido eliminad!`
            }
            this.$swal({
              icon: 'success',
              title,
              text,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Checkbox select item Table
    chkAll() {
      this.items.forEach(item => {
        const cliente = this.items.find(i => i.id === item.id)
        cliente.chkSelected = this.chkTodo
      })
    },
    chkCount() {
      let chkCount = 0
      this.items.forEach(item => {
        chkCount = item.chkSelected
          ? chkCount + 1
          : chkCount
      })
      return chkCount === 0
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
