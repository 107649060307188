<template>
  <div>
    <alumnosList />
  </div>
</template>

<script>
import alumnosList from './components/AlumnosList.vue'

export default {
  components: {
    alumnosList,
  },
}
</script>

<style>

</style>
